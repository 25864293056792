import * as React from "react";
import { Link } from "gatsby";

import { COLORS, getClosedNavItemSize } from "../utils/consts";
import { getIsVertical } from "../utils/getIsVertical";

import "./Navigation.css";

export enum PageId {
	Index,
	MobilePalliativeCare,
	HealthCenterLiepaja,
	PalliativeCareClinic,
	Rent,
	WhatIsRure,
}

type PageData = {
	[key in PageId]: {
		backgroundColor: string;
		closedBGColor?: string;
		closedContent?: React.ReactNode;
		url?: string;
		accentColor?: string;
		size?: number;
		menuTextColor?: string;
	};
}

export const VerticalContent: React.FC<{ content: string }> = ({ content }) => (
	<div className="verticalContent">
		<div className="verticalContentInner">
			<p style={{
				padding: 0,
				margin: 0,
			}}>
				{content}
			</p>
		</div>
	</div>
);

export const pageData: PageData = {
	[PageId.Index]: {
		backgroundColor: COLORS.white,
	},
	[PageId.MobilePalliativeCare]: {
		backgroundColor: COLORS.lightGreen,
		closedContent: <VerticalContent content={"Mobilā paliatīvā aprūpe mājās"} />,
		url: "/mobila-paliativa-aprupe-majas/",
	},
	[PageId.HealthCenterLiepaja]: {
		backgroundColor: COLORS.darkGreen,
		closedContent: <VerticalContent content={"Sociālās aprūpes centrs"} />,
		url: "/veselibas-centrs-liepaja/",
		accentColor: COLORS.lightGreen,
		menuTextColor: COLORS.white,
	},
	[PageId.PalliativeCareClinic]: {
		backgroundColor: COLORS.orange,
		closedContent: <VerticalContent content={"Paliatīvās aprūpes klīnika"} />,
		url: "/paliativas-aprupes-klinika/",
	},
	[PageId.Rent]: {
		backgroundColor: COLORS.lightBlue,
		closedContent: <VerticalContent content={getIsVertical() ? "Medicīniskie pakalpojumi/palīglīdzekļi" : "Medicīniskie pakalpojumi un palīglīdzekļu noma"} />,
		url: "/mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma/",
	},
	[PageId.WhatIsRure]: {
		backgroundColor: COLORS.yellow,
		closedContent: <VerticalContent content={"Kas ir Rūre?"} />,
		url: "/kas-ir-rure/",
		accentColor: COLORS.darkGreen,
	},
}

const ClosedWrapper: React.FC<{ url?: string }> = ({ url, children }) => url
	? <Link to={url} className="navigationLinkItem">{children}</Link>
	: <>{children}</>;

interface NavigationItemProps {
	pageId: PageId;
	currentPageId: PageId;
	isVertical: boolean;
}

const isBrowser = typeof window !== "undefined";

const NavigationItem: React.FC<NavigationItemProps> = ({ pageId, currentPageId, isVertical }) => {
	const isCurrent = pageId === currentPageId;

	const { backgroundColor, closedBGColor, accentColor = COLORS.white, menuTextColor } = pageData[pageId];

	if (isCurrent && isBrowser) {
		document.documentElement.style.backgroundColor = backgroundColor;
		document.body.style.color = accentColor;
		document.documentElement.style.setProperty("--accent-color", accentColor);
	}

	const {
		CLOSED_ITEM_SIZE_BEFORE,
		CLOSED_ITEM_SIZE_AFTER,
		CLOSED_ITEM_EXTRA,
	} = getClosedNavItemSize(isVertical);

	const { closedContent, url, size } = pageData[pageId];
	const afterCurrent = currentPageId < pageId;
	const closedItemSize = size
		?? (afterCurrent ? CLOSED_ITEM_SIZE_AFTER : CLOSED_ITEM_SIZE_BEFORE);
	const numberOfPages = Object.keys(PageId).length / 2;
	const isLast = numberOfPages - 1 === pageId;
	const finalSize = isLast && isVertical
		? closedItemSize + (afterCurrent ? CLOSED_ITEM_EXTRA : 0)
		: closedItemSize;

	return (
		<>
			{
				!!closedContent
					? (
						<ClosedWrapper url={isCurrent ? undefined : url}>
							<div
								className="closedContentWrapper"
								style={{
									backgroundColor: closedBGColor ?? backgroundColor,
									color: menuTextColor ?? accentColor,
									width: isVertical ? "100%" : `${finalSize}rem`,
									height: isVertical ? `${finalSize}rem` : "100%",
									borderRight: afterCurrent ? "" : isVertical ? "" : `1px solid ${accentColor}`,
									borderBottom: afterCurrent ? "" : isVertical ? `1px solid ${accentColor}` : "",
									alignItems: isLast && isVertical ? "flex-start" : "",
									paddingTop: isLast && isVertical ? "0.75rem" : "",
								}}
							>
								{closedContent}
							</div>
						</ClosedWrapper>
					)
					: null
			}
			{isCurrent ? <div className="pageContentSpace" /> : null}
		</>
	);
};

interface NavigationProps {
	currentPageId: PageId;
	isVertical: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ currentPageId, isVertical }) => {
	return (
		<div className="navigationWrapper">
			{
				Object.keys(pageData).map(pageId => (
					<NavigationItem
						key={`page-${PageId[parseInt(pageId)]}`}
						pageId={parseInt(pageId)}
						currentPageId={currentPageId}
						isVertical={isVertical}
					/>
				))
			}
		</div>
	);
}

export default Navigation;
